const intro = document.querySelector('.Intro');

function shouldShowIntro() {
  const urlPath = window.location.pathname;
  const lsShow = window.localStorage.getItem('introShown') !== 'true';

  return urlPath === '/' && lsShow;
}

if (intro) {
  const showIntro = shouldShowIntro();

  if (showIntro) {
    intro.classList.add('isOpen');
    window.localStorage.setItem('introShown', 'true');
  }

  const links = intro.querySelectorAll('a');

  links.forEach(link => {
    link.addEventListener('click', () => {
      intro.classList.remove('isOpen');
    });
  });
}
