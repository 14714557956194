import Glide from '@glidejs/glide';

const glides = document.querySelectorAll('.glide');

if (glides) {
  glides.forEach((glide) => {
    new Glide(glide, {
      autoplay: glide.hasAttribute('data-autoplay') ? 3000 : false,
      peek: glide.hasAttribute('data-peek') ? 300 : 0,
      perView: glide.hasAttribute('data-slides-visible') ? glide.getAttribute('data-slides-visible') : 1,
      breakpoints: {
        1500: {
          peek: glide.hasAttribute('data-peek') ? 150 : 0,
        },
        1100: {
          peek: glide.hasAttribute('data-peek') ? 65 : 0,
          perView: glide.hasAttribute('data-slides-visible') ? 3 : 1,
        },
        991: {
          perView: glide.hasAttribute('data-slides-visible') ? 2 : 1,
        },
        768: {
          peek: 0,
          perView: 1
        },
      }
    }).mount();
  });
}

const colorsTab = document.querySelectorAll('#color-tab button[data-bs-toggle="tab"]')

if (colorsTab.length) {
  colorsTab.forEach((btn) => {
    btn.addEventListener('shown.bs.tab', (e) => {
      const tab = document.querySelector(`${e.target.getAttribute('data-bs-target')} .glide`);
      new Glide(tab).mount();
    })
  })
}
