export default () => ({
  init() {
    if (window.location.search) {
      window.scroll(0, document.getElementById('stock-bikes-filter').offsetTop);
    }
  },
  onFilterChange() {
    document.getElementById('stock-bikes-filter').submit();
  }
})
