export default () => ({
  isNavbarOpen: false,
  isChildOpen: '',
  isDetailOpen: '',
  isMobileOpen: false,

  toggleNavbar() {
    this.isNavbarOpen = !this.isNavbarOpen

    if (this.isNavbarOpen && this.isMobileOpen) {
      document.body.style.overflow = 'hidden';
    } else if (!this.isMobileOpen && !this.isNavbarOpen) {
      document.body.style.overflow = 'visible';
    }
  },

  closeAndNavigate() {
    this.isNavbarOpen = false;
    this.isMobileOpen = false;
    this.isDetailOpen = '';
    this.isChildOpen = '';
    document.body.style.overflow = 'visible';
  },

  openChild(child) {
    const target = this.$refs[`parent_${child}`];

    this.toggleNavbar();
    if (child == this.isChildOpen) {
      this.isChildOpen = '';
    } else {
      if (window.innerWidth > 991) {
        const w = target.offsetWidth;
        target.style.flex = `0 0 ${w}px`;
      }
      this.isChildOpen = child;
    }
  },

  openDetail(detail) {
    this.isDetailOpen = detail;
  },

  openMobile() {
    this.isMobileOpen = !this.isMobileOpen;

    if (this.isMobileOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }
})
