// Libs
import Alpine from 'alpinejs';
import 'bootstrap/dist/js/bootstrap';

// Components
import './mobileDetect';
import navbar from '../views/components/Navbar/Navbar';
import stockBikes from '../views/components/StockBikes/StockBikes';
import '../views/components/MainHeader/MainHeader';
import '../views/components/Intro/Intro';
import './glide'; // fslightbox
// import './fadeIn'; // Simple fade in
// import './parallax'; // Simple prallax with Rellax

window.Alpine = Alpine;

Alpine.data('navbar', navbar);
Alpine.data('stockBikes', stockBikes);

Alpine.start();
